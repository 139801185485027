import React from "react";
import logo from "../logo.png";
import logosolid from "../logo-solid.png";
import imgbanner from "../image-banner-home6.png";
import imgatm from "../images/atm.png";
import imgmbanking from "../images/mbanking.png";
import imginternetbank from "../images/internetbank.png";
import imgtallerBca from "../images/tallerBca.png";
import imgatmbersama from "../images/atmbersama.png";
import imgcariInv from "../images/cariInv.PNG";
import imgdatainv from "../images/datainv.PNG";
import imgdatatagihan from "../images/datatagihan.PNG";
import imgmetodpay from "../images/metodpay.PNG";
import imgqris from "../images/qris.png";
import imgbcam from "../images/bcam.png";
import imgklikbca from "../images/klikbca.png";
import { Link } from "react-router-dom";

function Carabayar() {
  return (
    <>
      <div id="page" class="site">
        <header
          id="site-header"
          class="site-header header-style-1 home-6 header-overlay header-fullwidth sticky-header mobile-header-sticky"
        >
          <div class="octf-main-header">
            <div class="octf-area-wrap">
              <div class="container-fluid octf-mainbar-container">
                <div class="octf-mainbar">
                  <div class="octf-mainbar-row octf-row">
                    <div class="octf-col">
                      <div id="site-logo" class="site-logo">
                        <a href="/">
                          <img class="logo-static" src={logo} alt="Onum" />
                          <img class="logo-scroll" src={logo} alt="Onum" />
                        </a>
                      </div>
                    </div>

                    <div class="octf-col">
                      <nav id="site-navigation" class="main-navigation">
                        <ul id="primary-menu" class="menu">
                          <li
                            data-spy="scroll"
                            class=""
                          >
                            <a href="/">Home</a>
                          </li>
                          <li class="">
                            <a href="#transfer">Pembayaran Transfer</a>
                          </li>
                          <li class="">
                            <a href="#aplikasi">Pembayaran Aplikasi</a>
                          </li>
                          <li class="current-menu-item current-menu-ancestor">
                            <Link to="">Cara Bayar</Link>
                          </li>
                        </ul>
                      </nav>
                    </div>

                    <div class="octf-col text-right">
                      <div class="octf-btn-cta">
                        <div class="octf-header-module">
                          <div class="btn-cta-group">
                            <a
                              class="octf-btn octf-btn-primary"
                              href="tel:+622230501500"
                            >
                              <i class="fa fa-phone"></i>&nbsp; +62 22-3050 1500
                            </a>
                          </div>
                        </div>
                        <div class="octf-header-module">
                          <div class="btn-cta-group">
                            <a
                              class="octf-btn octf-btn-primary"
                              target="_blank"
                              href="mailto:sapa@urbanaccess.net"
                            >
                              <i class="fa fa-envelope"></i>&nbsp;
                              sapa@urbanaccess.net
                            </a>
                          </div>
                        </div>
                        <div class="octf-header-module">
                          <div class="toggle_search octf-cta-icons">
                            <i class="flaticon-search"></i>
                          </div>

                          <div class="h-search-form-field">
                            <div class="h-search-form-inner">
                              <form
                                target="_blank"
                                role="search"
                                method="get"
                                id="search-form"
                                class="search-form"
                                action="https://www.google.com/search?q=urbanaccess.net&oq=urbanaccess.net"
                              >
                                <input
                                  type="search"
                                  class="search-field"
                                  placeholder="Search…"
                                  value=""
                                  name="s"
                                />
                                <button type="submit" class="search-submit">
                                  <i class="flaticon-search"></i>
                                </button>
                              </form>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="header_mobile">
            <div class="container">
              <div class="mlogo_wrapper clearfix">
                <div class="mobile_logo">
                  <a href="/">
                    <img src={logosolid} alt="Onum" />
                  </a>
                </div>

                <div id="mmenu_toggle">
                  <button></button>
                </div>
              </div>

              <div class="mmenu_wrapper">
                <div class="mobile_nav">
                  <ul id="menu-main-menu" class="mobile_mainmenu">
                    <li class="current-menu-item current-menu-ancestor">
                      <a href="/">Home</a>
                    </li>
                    <li class="">
                      <a href="#product">Products</a>
                    </li>
                    <li class="">
                      <a href="#about">About</a>
                    </li>
                    <li class="">
                      <a href="#contact">Contact</a>
                    </li>
                    <li class="">
                      <Link to="/carabayar">Cara Bayar</Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </header>

        <div id="content" class="site-content">
          <section class="home6-top">
            <div class="container">
              <div class="flex-row">
                <div class="col-md-6 col-sm-12 col-xs-12">
                  <div class="home6-top-img">
                    <img src={imgbanner} alt="" />
                  </div>
                </div>
                <div class="col-md-6 col-sm-12 col-xs-12">
                  <div class="home6-top-block">
                    <h6>U R B A N &nbsp; A C C E S S &nbsp; .net</h6>
                    <h2>Cara Pembayaran Urban Access</h2>
                    <p>
                      Panduan cara pembayaran untuk layanan <strong>Urban Access</strong>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section class="home6-partners" id="transfer">
            <div class="container">
              <div class="flex-row">
                <div class="col-md-6 col-sm-12 col-xs-12 align-self-center">
                  <div class="home6-partners-img">
                    <img src={imgtallerBca} alt="" class="image-icon image-left" />
                  </div>
                </div>
                <div class="col-md-6 col-sm-12 col-xs-12 align-self-center">
                  <div class="home6-partners-block">
                    <div class="ot-heading left-align">
                      <h6 class="octf-btn octf-btn-icon octf-btn-secondary">
                        <span class="font22 text-dark">Teller Bank BCA</span>
                      </h6>
                    </div>
                    <ol class="text-light">
                      <li>Melengkapi form dengan detail berikut :</li>
                      <ul>
                          <li>Bank Tujuan : “Bank BCA”</li>
                          <li>Nomor Rekening Tujuan : No. Rek. Urban Access 2432777503</li>
                          <li>Terbilang : “Masukan sesuai dengan jumlah tagihan”</li>
                          <li>Nama Penerima : “PT Media Distribusi Prima/Urban Access”</li>
                          <li>Alamat Penerima : “Jl. Kangkung Kidul No.18 & No Registrasi”</li>
                      </ul>
                      <li>Teller akan memproses dan mencetak bukti pembayaran</li>
                      <li>Kirim foto bukti bayar ke Billing Urban Access melalui WA No. 08112020188</li>
                  </ol>
                  </div>
                </div>
              </div>
            </div>
            <div class="container">
              <div class="flex-row">
                <div class="col-md-6 col-sm-12 col-xs-12 align-self-center">
                  <div class="home6-partners-block">
                    <div class="ot-heading left-align">
                      <h6 class="octf-btn octf-btn-icon octf-btn-secondary">
                        <span class="font22 text-dark">Mobile BCA</span>
                      </h6>
                    </div>
                    <ol class="text-light">
                      <li>Buka aplikasi BCA Mobile</li>
                      <li>Pilih “m-BCA” dan masukan “m-BCA PIN”</li>
                      <li>Pilih “m-Transfer”, pilih Antar Rekening BCA dan Daftarkan No Rek BCA Urban Access 2432777503</li>
                      <li>Kembali ke menu Transfer</li>
                      <li>Pilih “m-Transfer”, lalu pilih Antar Rekening BCA dan pilih No Rek BCA Urban Access 2432777503</li>
                      <li>Masukan Jumlah pembayaran sesuai dengan Tagihan</li>
                      <li>Isi Berita dengan No Registrasi Pelanggan</li>
                      <li>Ikuti langkah selanjutnya sampai pembayaran telah berhasil dilakukan</li>
                      <li>Kirim foto bukti bayar ke Billing Urban Access melalui WA No. 08112020188</li>
                    </ol>
                  </div>
                </div>
                <div class="col-md-6 col-sm-12 col-xs-12 align-self-center">
                  <div class="home6-partners-img">
                    <img src={imgmbanking} alt="" class="image-icon image-left" />
                  </div>
                </div>
              </div>
            </div>
            <div class="container">
              <div class="flex-row">
                <div class="col-md-6 col-sm-12 col-xs-12 align-self-center">
                  <div class="home6-partners-img">
                    <img src={imginternetbank} alt="" class="image-icon-small image-right"/>
                  </div>
                </div>
                <div class="col-md-6 col-sm-12 col-xs-12 align-self-center">
                  <div class="home6-partners-block">
                    <div class="ot-heading left-align">
                      <h6 class="octf-btn octf-btn-icon octf-btn-secondary">
                        <span class="font22 text-dark">Klik BCA</span>
                      </h6>
                    </div>
                      <ol class="text-light">
                        <li>Masuk ke halaman KlikBCA (https://ibank.klikbca.com/) dan masukan User ID dan PIN Internet Banking</li>
                        <li>Daftar Rekening BCA PT Media Distribusi Prima/Urban Access 2432777503</li>
                        <li>Pilih “Transfer ke Rek BCA”, Pilih No Rek BCA PT Media Distribusi Prima</li>
                        <li>Pastikan detail pembayaran telah sesuai</li>
                        <li>Isi berita dengan No Registrasi dan silakan masukan nomor “keyBCA” dan klik “Kirim”</li>
                        <li>Ikuti langkah selanjutnya sampai pembayaran telah berhasil dilakukan</li>
                        <li>Kirim foto bukti bayar ke Billing Urban Access melalui WA No. 08112020188</li>
                      </ol>
                  </div>
                </div>
              </div>
            </div>
            <div class="container">
              <div class="flex-row">
                <div class="col-md-6 col-sm-12 col-xs-12 align-self-center">
                  <div class="home6-partners-block">
                    <div class="ot-heading left-align">
                      <h6 class="octf-btn octf-btn-icon octf-btn-secondary">
                        <span class="font22 text-dark">ATM BCA</span>
                      </h6>
                    </div>
                    <ol class="text-light">
                        <li>Masukan kartu dan PIN Bank BCA</li>
                        <li>Pilih “Transaksi Lainnya” dan“Transfer Ke Rekening BCA”</li>
                        <li>Masukan No Rek BCA Urban Access 2432777503</li>
                        <li>Masukan Jumlah pembayaran sesuai dengan Tagihan</li>
                        <li>Ikuti langkah selanjutnya sampai pembayaran telah berhasil dilakukan</li>
                        <li>Kirim foto bukti bayar ke Billing Urban Access melalui WA No. 08112020188</li>
                    </ol>
                  </div>
                </div>
                <div class="col-md-6 col-sm-12 col-xs-12 align-self-center">
                  <div class="home6-partners-img">
                    <img src={imgatm} alt="" class="image-icon" />
                  </div>
                </div>
              </div>
            </div>
            <div class="container">
              <div class="flex-row">
                <div class="col-md-6 col-sm-12 col-xs-12 align-self-center">
                  <div class="home6-partners-img">
                    <img src={imgatmbersama} alt="" class="image-icon-small image-left"/>
                  </div>
                </div>
                <div class="col-md-6 col-sm-12 col-xs-12 align-self-center">
                  <div class="home6-partners-block">
                    <div class="ot-heading left-align">
                      <h6 class="octf-btn octf-btn-icon octf-btn-secondary">
                        <span class="font22 text-dark">ATM Bersama ke BCA</span>
                      </h6>
                    </div>
                      <ol class="text-light">
                        <li>Pilih “Menu Lainnya”</li>
                        <li>Pilih “Transfer”</li>
                        <li>Masukan kode bank tujuan (Kode Bank BCA “014”) dan No Rek BCA Urban Access 2432777503 dan Pilih “TEKAN JIKA BENAR”</li>
                        <li>Masukan jumlah pembayaran sesuai tagihan dan pilih “BENAR”</li>
                        <li>Ikuti langkah selanjutnya sampai pembayaran telah berhasil dilakukan</li>
                        <li>Kirim foto bukti bayar ke Billing Urban Access melalui WA No. 08112020188</li>
                    </ol>
                  </div>
                </div>
              </div>
            </div>
            <div class="container">
              <div class="flex-row">
                <div class="col-md-6 col-sm-12 col-xs-12 align-self-center">
                  <div class="home6-partners-block">
                    <div class="ot-heading left-align">
                      <h6 class="octf-btn octf-btn-icon octf-btn-secondary">
                        <span class="font22 text-dark">ATM Mandiri</span>
                      </h6>
                    </div>
                      <ol class="text-light">
                          <li>Masukan kartu dan PIN Bank Mandiri</li>
                          <li>Pilih “Transaksi Lainnya” dan pilih “Transfer”</li>
                          <li>Pilih “Ke Rekening Mandiri” dan masukan No Rek Mandiri Urban Access 1300028888850, lalu pilih “ Benar”</li>
                          <li>Masukan Jumlah uang sesuai dengan tagihan, lalu pilih “Benar”</li>
                          <li>Ikuti langkah selanjutnya sampai pembayaran telah berhasil dilakukan</li>
                          <li>Kirim foto bukti bayar ke Billing Urban Access melalui WA No. 08112020188</li>
                      </ol>
                  </div>
                </div>
                <div class="col-md-6 col-sm-12 col-xs-12 align-self-center">
                  <div class="home6-partners-img">
                    <img src={imgatm} alt="" class="image-icon" />
                  </div>
                </div>
              </div>
            </div>
            <div class="container">
              <div class="flex-row">
                <div class="col-md-6 col-sm-12 col-xs-12 align-self-center">
                  <div class="home6-partners-img">
                    <img src={imginternetbank} alt="" class="image-icon-small image-left"/>
                  </div>
                </div>
                <div class="col-md-6 col-sm-12 col-xs-12 align-self-center">
                  <div class="home6-partners-block">
                    <div class="ot-heading left-align">
                      <h6 class="octf-btn octf-btn-icon octf-btn-secondary">
                        <span class="font22 text-dark">Internet Banking Mandiri</span>
                      </h6>
                    </div>
                      <ol class="text-light">
                        <li>Masukan User ID dan PIN akun Mandiri Internet Banking</li>
                        <li>Pilih “Transfer” dan pilih Rekening Mandiri</li>
                        <li>Pilih Rekening asal, Jumlah sesuai dengan tagihan, rekening tujuan “No Rekening Bank Mandiri : 1300028888850“</li>
                        <li>Deskripsi “ No Registrasi Pelanggan” kemudian pilih Lanjutkan</li>
                        <li>Ikuti langkah selanjutnya sampai pembayaran telah berhasil dilakukan</li>
                        <li>Kirim foto bukti bayar ke Billing Urban Access melalui WA No. 08112020188</li>
                    </ol>
                  </div>
                </div>
              </div>
            </div>
            <div class="container">
              <div class="flex-row">
                <div class="col-md-6 col-sm-12 col-xs-12 align-self-center">
                  <div class="home6-partners-block">
                    <div class="ot-heading left-align">
                      <h6 class="octf-btn octf-btn-icon octf-btn-secondary">
                        <span class="font22 text-dark">Livin by Mandiri</span>
                      </h6>
                    </div>
                      <ol class="text-light">
                        <li>Masukan User ID dan password Mandiri Mobile Banking</li>
                        <li>Pilih “Transfer” dan pilih “Ke Rekening Mandiri”</li>
                        <li>Tentukan “Rekening Sumber”, Isi Rekening Tujuan dengan “No Rek Mandiri Urban Access 1300028888850”, Jumlah Transfer sesuai dengan tagihan dan Deskripsi isi dengan “No Registrasi Pelanggan” serta pilih “ Lanjut”</li>
                        <li>Ikuti langkah selanjutnya sampai pembayaran telah berhasil dilakukan</li>
                        <li>Kirim foto bukti bayar ke Billing Urban Access melalui WA No. 08112020188</li>
                    </ol>
                  </div>
                </div>
                <div class="col-md-6 col-sm-12 col-xs-12 align-self-center">
                  <div class="home6-partners-img">
                    <img src={imgmbanking} alt="" class="image-icon image-left" />
                  </div>
                </div>
              </div>
            </div>
            <div class="container">
              <div class="flex-row">
                <div class="col-md-6 col-sm-12 col-xs-12 align-self-center">
                  <div class="home6-partners-img">
                    <img src={imgatmbersama} alt="" class="image-icon-small image-left"/>
                  </div>
                </div>
                <div class="col-md-6 col-sm-12 col-xs-12 align-self-center">
                  <div class="home6-partners-block">
                    <div class="ot-heading left-align">
                      <h6 class="octf-btn octf-btn-icon octf-btn-secondary">
                        <span class="font22 text-dark">ATM Bersama Ke Mandiri</span>
                      </h6>
                    </div>
                      <ol class="text-light">
                        <li>Pilih “Menu Lainnya”</li>
                        <li>Pilih “Transfer”</li>
                        <li>Masukan kode bank tujuan (Kode Bank BCA “008”) dan No Rekening Bank Mandiri : 1300028888850 dan Pilih “TEKAN JIKA BENAR”</li>
                        <li>Masukan jumlah pembayaran sesuai tagihan dan pilih “BENAR”</li>
                        <li>Ikuti langkah selanjutnya sampai pembayaran telah berhasil dilakukan</li>
                        <li>Kirim foto bukti bayar ke Billing Urban Access melalui WA No. 08112020188</li>
                    </ol>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section class="home6-partners" id="aplikasi">
            <div class="container">
              <div class="flex-row">
                <h3 class="text-light">Cara melakukan transaksi melalui apliasi Urban Payment:</h3>
                <div class="col-md-6 col-sm-12 col-xs-12 align-self-center">
                  <div class="home6-partners-block">
                    <ol class="text-light">
                      <li>silahkan buka aplikasi Urban Payment dan masukan nomor registrasi atau nomor handphone, lalu klik tombol cari</li>
                      <ul>
                          <li><img src={imgcariInv} alt="" /></li>
                      </ul>
                      <li>Setelah itu akan muncul data tagihan pembayaran, lalu klik detail salah satu tagihan yang akan dibayar, lalu pilih pilihan bayar</li>
                      <ul>
                          <li><img src={imgdatainv} alt="" /></li>
                      </ul>
                      <li>Maka akan diarahkan ke halaman detail tagihan, lalu klik tombol bayar untuk melakukan transaksi</li>
                      <ul>
                          <li><img src={imgdatatagihan} alt="" /></li>
                      </ul>
                      <li>Maka akan muncul tampilan metode pembayaran, silahkan pilih metode pembayaran yang tersedia</li>
                      <ul>
                          <li><img src={imgmetodpay} alt="" /></li>
                      </ul>
                  </ol>
                  </div>
                </div>
              </div>
            </div>
            <div class="container">
              <div class="flex-row">
                <div class="col-md-6 col-sm-12 col-xs-12 align-self-center">
                  <div class="home6-partners-block">
                    <div class="ot-heading left-align">
                      <h6 class="octf-btn octf-btn-icon octf-btn-secondary">
                        <span class="font22 text-dark">QRis</span>
                      </h6>
                    </div>
                    <ol class="text-light">
                      <li>Buka Aplikasi yang mendukung pembayaran dengan QRis</li>
                      <li>Pindai QRis Pada monitor</li>
                      <li>Konfirmasi pembayaran pada apliasi QRis</li>
                      <li>Simpan bukti pembayaran apabila diperlukan</li>
                    </ol>
                  </div>
                </div>
                <div class="col-md-6 col-sm-12 col-xs-12 align-self-center">
                  <div class="home6-partners-img">
                    <img src={imgqris} alt="" class="image-icon" />
                  </div>
                </div>
              </div>
            </div>
            <div class="container">
              <div class="flex-row">
                <div class="col-md-6 col-sm-12 col-xs-12 align-self-center">
                  <div class="home6-partners-img">
                    <img src={imgbcam} alt="" class="image-icon-small image-right"/>
                  </div>
                </div>
                <div class="col-md-6 col-sm-12 col-xs-12 align-self-center">
                  <div class="home6-partners-block">
                    <div class="ot-heading left-align">
                      <h6 class="octf-btn octf-btn-icon octf-btn-secondary">
                        <span class="font22 text-dark">Mobile BCA</span>
                      </h6>
                    </div>
                      <ol class="text-light">
                        <li>Login ke aplikasi BCA Mobile, lalu pilih “m-BCA” dan masukkan kode akses</li>
                        <li>Pilih “m-Transfer”, lalu lanjut ke “BCA Virtual Account”</li>
                        <li>Masukkan nomor virtual account lalu masukkan jumlah yang akan dibayarkan</li>
                        <li>Masukkan PIN m-BCA</li>
                        <li>Simpan bukti pembayaran apabila diperlukan</li>
                      </ol>
                  </div>
                </div>
              </div>
            </div>
            <div class="container">
              <div class="flex-row">
                <div class="col-md-6 col-sm-12 col-xs-12 align-self-center">
                  <div class="home6-partners-block">
                    <div class="ot-heading left-align">
                      <h6 class="octf-btn octf-btn-icon octf-btn-secondary">
                        <span class="font22 text-dark">Klik BCA</span>
                      </h6>
                    </div>
                    <ol class="text-light">
                        <li>Login ke aplikasi Klik BCA Individu, masukkan user ID dan PIN</li>
                        <li>Pilih menu “Transfer Dana”, selanjutnya pilih “Transfer ke BCA Virtual Account”</li>
                        <li>Masukkan nomor virtual account yang merupakan tujuan pembayaran Anda</li>
                        <li>Masukkan jumlah pembayaran dan lakukan validasi untuk memastikan semuanya selesai</li>
                        <li>Masukkan kode yang diperoleh dari KEYBCA APPLI 1, kemudian klik “Kirim”</li>
                        <li>Simpan bukti pembayaran apabila diperlukan</li>
                    </ol>
                  </div>
                </div>
                <div class="col-md-6 col-sm-12 col-xs-12 align-self-center">
                  <div class="home6-partners-img">
                    <img src={imgklikbca} alt="" class="image-icon-small image-right" />
                  </div>
                </div>
              </div>
            </div>
            <div class="container">
              <div class="flex-row">
                <div class="col-md-6 col-sm-12 col-xs-12 align-self-center">
                  <div class="home6-partners-img">
                    <img src={imgatm} alt="" class="image-icon-small image-left"/>
                  </div>
                </div>
                <div class="col-md-6 col-sm-12 col-xs-12 align-self-center">
                  <div class="home6-partners-block">
                    <div class="ot-heading left-align">
                      <h6 class="octf-btn octf-btn-icon octf-btn-secondary">
                        <span class="font22 text-dark">ATM BCA</span>
                      </h6>
                    </div>
                      <ol class="text-light">
                        <li>Masukkan kartu dan ketikkan nomor PIN di ATM BCA</li>
                        <li>Pilih menu “Transaksi Lainnya”, lalu pilih “Transfer”</li>
                        <li>Pilih “ke Rekening BCA Virtual Account”</li>
                        <li>Masukkan nomor virtual account, kemudian tekan “Benar”</li>
                        <li>Masukkan nominal yang akan dibayarkan, lalu tekan “Benar” lagi</li>
                        <li>Setelah layar menampilkan informasi pembayaran, silakan cek kembali nomor virtual account, total pembayaran, serta informasi lainnya</li>
                        <li>Jika seluruhnya sesuai, maka tekan “Ya”</li>
                        <li>Simpan bukti pembayaran apabila diperlukan</li>
                    </ol>
                  </div>
                </div>
              </div>
            </div>
            <div class="container">
              <div class="flex-row">
                <div class="col-md-6 col-sm-12 col-xs-12 align-self-center">
                  <div class="home6-partners-block">
                    <div class="ot-heading left-align">
                      <h6 class="octf-btn octf-btn-icon octf-btn-secondary">
                        <span class="font22 text-dark">ATM Mandiri</span>
                      </h6>
                    </div>
                      <ol class="text-light">
                          <li>Masukkan kartu ATM dan PIN ATM</li>
                          <li>Pilih menu Bayar/Beli</li>
                          <li>Pilih opsi Lainnya, Multipayment.</li>
                          <li>Masukkan kode biller perusahaan (biasanya sudah tercantum di instruksi pembayaran)</li>
                          <li>Masukkan nomor Virtual account, Benar</li>
                          <li>Masukkan angka yang diminta untuk memilih tagihan, Ya</li>
                          <li>Layar akan menampilkan konfirmasi. Jika sesuai, pilih Ya</li>
                          <li>Simpan bukti pembayaran apabila diperlukan</li>
                      </ol>
                  </div>
                </div>
                <div class="col-md-6 col-sm-12 col-xs-12 align-self-center">
                  <div class="home6-partners-img">
                    <img src={imgatm} alt="" class="image-icon-small image-right" />
                  </div>
                </div>
              </div>
            </div>
            <div class="container">
              <div class="flex-row">
                <div class="col-md-6 col-sm-12 col-xs-12 align-self-center">
                  <div class="home6-partners-img">
                    <img src={imginternetbank} alt="" class="image-icon-small image-left"/>
                  </div>
                </div>
                <div class="col-md-6 col-sm-12 col-xs-12 align-self-center">
                  <div class="home6-partners-block">
                    <div class="ot-heading left-align">
                      <h6 class="octf-btn octf-btn-icon octf-btn-secondary">
                        <span class="font22 text-dark">Internet Banking Mandiri</span>
                      </h6>
                    </div>
                      <ol class="text-light">
                        <li>Akses situs Mandiri Online, masukkan username dan password</li>
                        <li>Pilih menu Pembayaran, Multipayment</li>
                        <li>Pilih Penyedia Jasa yang digunakan</li>
                        <li>Masukkan nomor virtual account Mandiri dan nominal yang akan dibayarkan, Lanjut</li>
                        <li>Layar akan menampilkan konfirmasi. Apabila sesuai, lanjutkan dengan pilih Konfirmasi</li>
                        <li>Masukkan PIN token</li>
                        <li>Simpan bukti pembayaran apabila diperlukan</li>
                    </ol>
                  </div>
                </div>
              </div>
            </div>
            <div class="container">
              <div class="flex-row">
                <div class="col-md-6 col-sm-12 col-xs-12 align-self-center">
                  <div class="home6-partners-block">
                    <div class="ot-heading left-align">
                      <h6 class="octf-btn octf-btn-icon octf-btn-secondary">
                        <span class="font22 text-dark">Livin by Mandiri</span>
                      </h6>
                    </div>
                      <ol class="text-light">
                        <li>Buka aplikasi m-banking</li>
                        <li>Masukkan username dan password</li>
                        <li>Pilih menu Bayar, One Time, Multipayment</li>
                        <li>Pilih Penyedia Jasa yang digunakan, Lanjut</li>
                        <li>Masukkan nomor Virtual account, Lanjut</li>
                        <li>Layar akan menampilkan konfirmasi. Jika sudah sesuai, masukkan PIN transaksi dan akhiri dengan OK</li>
                        <li>Simpan bukti pembayaran apabila diperlukan</li>
                    </ol>
                  </div>
                </div>
                <div class="col-md-6 col-sm-12 col-xs-12 align-self-center">
                  <div class="home6-partners-img">
                    <img src={imgmbanking} alt="" class="image-icon-small image-right" />
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>

        <footer
          id="site-footer"
          class="site-footer site-footer-2 bg-gradient-2"
        >
          <div class="footer-bottom"></div>
          <div class="container">
            <p class="copyright-2 text-center">
              {" "}
              Disclaimer : <br /> Harga yang tercantum dalam website dapat
              berubah sewaktu-waktu sesuai dengan syarat dan ketentuan yang
              berlaku.
              <br />
              Untuk informasi lebih lanjut, silakan hubungi Customer Care kami
              di +62 22 30501500 atau melalui email ke sapa@urbanaccess.net.{" "}
            </p>
            <p class="copyright-2 text-center">
              Copyright © 2022 UrbanAccess .NET, Support by{" "}
              <a target="_blank" href="http://www.dipointer.com">
                dipointer
              </a>
            </p>
          </div>
        </footer>
        <a id="back-to-top" href="#" class="show">
          <i class="flaticon-arrow-pointing-to-up"></i>
        </a>
      </div>
    </>
  );
}

export default Carabayar;
