import Swal from "sweetalert2";

export const showAlert = (params, resp) => {
  Swal.fire({
    title: params === true ? "Success" : "Error",
    text: resp,
    icon: params === true ? "success" : "error",
    confirmButtonText: "OK",
  });
};
