import React from "react";
import logo from "../logo.png";
import logosolid from "../logo-solid.png";
import imgbanner from "../image-banner-home6.png";
import imgbannerperson from "../image1-home6.png";
import imgpersonal from "../pricing1-home4-1.png";
import imgsoho from "../pricing2-home4-1.png";
import imgdedicated from "../pricing3-home4-1.png";
import { Link } from "react-router-dom";

function Home() {
  return (
    <>
      <div id="page" class="site">
        <header
          id="site-header"
          class="site-header header-style-1 home-6 header-overlay header-fullwidth sticky-header mobile-header-sticky"
        >
          <div class="octf-main-header">
            <div class="octf-area-wrap">
              <div class="container-fluid octf-mainbar-container">
                <div class="octf-mainbar">
                  <div class="octf-mainbar-row octf-row">
                    <div class="octf-col">
                      <div id="site-logo" class="site-logo">
                        <a href="/">
                          <img class="logo-static" src={logo} alt="Onum" />
                          <img class="logo-scroll" src={logo} alt="Onum" />
                        </a>
                      </div>
                    </div>

                    <div class="octf-col">
                      <nav id="site-navigation" class="main-navigation">
                        <ul id="primary-menu" class="menu">
                          <li
                            data-spy="scroll"
                            class=""
                          >
                            <a href="/">Home</a>
                          </li>
                          <li class="">
                            <a href="#product">Products</a>
                          </li>
                          <li class="">
                            <a href="#about">About</a>
                          </li>
                          <li class="">
                            <a href="#contact">Contact</a>
                          </li>
                          <li class="">
                            <Link to="minat">Registrasi Online</Link>
                          </li>
                          <li class="">
                            <Link to="/carabayar">Cara Bayar</Link>
                          </li>
                          <li class="current-menu-item current-menu-ancestor">
                            <Link to="/career">Karir</Link>
                          </li>
                        </ul>
                      </nav>
                    </div>

                    <div class="octf-col text-right">
                      <div class="octf-btn-cta">
                        <div class="octf-header-module">
                          <div class="btn-cta-group">
                            <a
                              class="octf-btn octf-btn-primary"
                              href="tel:+622230501500"
                            >
                              <i class="fa fa-phone"></i>&nbsp; +62 22-3050 1500
                            </a>
                          </div>
                        </div>
                        <div class="octf-header-module">
                          <div class="btn-cta-group">
                            <a
                              class="octf-btn octf-btn-primary"
                              target="_blank"
                              href="mailto:sapa@urbanaccess.net"
                            >
                              <i class="fa fa-envelope"></i>&nbsp;
                              sapa@urbanaccess.net
                            </a>
                          </div>
                        </div>
                        <div class="octf-header-module">
                          <div class="toggle_search octf-cta-icons">
                            <i class="flaticon-search"></i>
                          </div>

                          <div class="h-search-form-field">
                            <div class="h-search-form-inner">
                              <form
                                target="_blank"
                                role="search"
                                method="get"
                                id="search-form"
                                class="search-form"
                                action="https://www.google.com/search?q=urbanaccess.net&oq=urbanaccess.net"
                              >
                                <input
                                  type="search"
                                  class="search-field"
                                  placeholder="Search…"
                                  value=""
                                  name="s"
                                />
                                <button type="submit" class="search-submit">
                                  <i class="flaticon-search"></i>
                                </button>
                              </form>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="header_mobile">
            <div class="container">
              <div class="mlogo_wrapper clearfix">
                <div class="mobile_logo">
                  <a href="/">
                    <img src={logosolid} alt="Onum" />
                  </a>
                </div>

                <div id="mmenu_toggle">
                  <button data-bs-target="#mobile_nav" aria-controls="mobile_nav"></button>
                </div>
              </div>

              <div class="mmenu_wrapper" id="mobile_nav">
                <div class="mobile_nav">
                  <ul id="menu-main-menu" class="mobile_mainmenu">
                    <li class="current-menu-item current-menu-ancestor">
                      <a href="/">Home</a>
                    </li>
                    <li class="">
                      <a href="#product">Products</a>
                    </li>
                    <li class="">
                      <a href="#about">About</a>
                    </li>
                    <li class="">
                      <a href="#contact">Contact</a>
                    </li>
                    <li class="">
                      <Link to="/carabayar">Cara Bayar</Link>
                    </li>
                    <li class="">
                      <Link to="/carabayar">Karir</Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </header>

        <div id="content" class="site-content">
          <section class="home6-top" id="product">
            <div class="bg-overlay"></div>
            <div class="container">
              <div class="row">
                <div class="col-md-12 col-xs-12 text-center m-b40">
                  <div class="ot-heading">
                    <h2 class="main-heading text-light">Lowongan Tersedia</h2>
                  </div>
                  <p class="text-light">
                    "Bergabunglah dengan Tim Kami: Membuka Peluang, Membangun Karir."
                  </p>
                </div>
              </div>
              <div class="row">

                <div class="col-md-12 sm-p-b60">
                  <div class="ot-pricing-table-h6 ot-pricing-table bg-shape s1">

                    <div class="inner-table">
                      <img src={imgpersonal} alt="Economy" />
                      <h2>URBAN CAREER</h2>
                      <div class="details">
                        <table class="table text-light">
                          <thead>
                            <tr>
                              <td class="text-center">No</td>
                              <td class="text-center">Nama Posisi</td>
                              <td class="text-center">Deskripsi Pekerjaan</td>
                              <td class="text-center">Kualifikasi</td>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>1</td>
                              <td>Tax Accounting</td>
                              <td>
                                <ol>
                                  <li>Menghitung dan menyiapkan pembayaran pajak.</li>
                                  <li>Memperkirakan dan melacak pengembalian pajak.</li>
                                  <li>Melengkapi laporan pajak 3 bulanan dan tahunan.</li>
                                  <li>Menyimpan dan membuat database pajak perusahaan secara rapi.</li>
                                  <li>Menyiapkan dokumen yang diperlukan untuk pembayaran dan pengembalian pajak.</li>
                                  <li>Mengidentifikasi pembayaran pajak untuk meningkatkan laba.</li>
                                  <li>Mengikuti peraturan terbaru tentang perpajakan.</li>
                                  <li>Berkoordinasi dengan auditor internal dan eksternal.</li>
                                </ol>
                              </td>
                              <td>
                                <ol>
                                  <li>Memiliki pengalaman kerja sebagai tax accountant, analisis pajak atau penyusun pajak.</li>
                                  <li>Mahir menggunakan software akuntansi.</li>
                                  <li>Memiliki pengetahuan tentang akuntansi dan pembukuan.</li>
                                  <li>Mahir menggunakan Ms Word dan Ms Excel.</li>
                                  <li>Memiliki kemampuan analisi yang kuat dan manajemen waktu yang baik.</li>
                                  <li>Siap di target dan mampu bekerja dibawah tekanan.</li>
                                  <li>Memiliki keterampilan berhitung yang kuat.</li>
                                  <li>Detail dan teliti terhadap kesalahan.</li>
                                  <li>Merupakan lulusan jurusan Akuntansi / Keuangan / Perpajakan.</li>
                                </ol>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <a
                      href="https://docs.google.com/forms/d/1ogaKuZ5Ge9YQW3p6WMPBisIbX7Gig5PZ_0P3VbkizsE/viewform?edit_requested=true"
                      class="octf-btn octf-btn-icon octf-btn-third" target="_blank"
                    >
                      Lamar<i class="fa fa-envelope"></i>
                    </a>
                  </div>
                </div>
                <br/>
                <br/>
                <p class="text-light">* Untuk lowongan posisi lain silakan <a href="https://docs.google.com/forms/d/1ogaKuZ5Ge9YQW3p6WMPBisIbX7Gig5PZ_0P3VbkizsE/viewform?edit_requested=true" class="octf-btn octf-btn-third" target="_blank">Klik Disini</a></p>
              </div>
            </div>
            <br />
            <br />
          </section>

        </div>

        <footer
          id="site-footer"
          class="site-footer site-footer-2 bg-gradient-2"
        >
          <div class="footer-bottom"></div>
          <div class="container">
            <p class="copyright-2 text-center">
              {" "}
              <br />
              Untuk informasi lebih lanjut, silakan hubungi Customer Care kami
              di +62 22 30501500 atau melalui email ke sapa@urbanaccess.net.{" "}
            </p>
            <p class="copyright-2 text-center">
              Copyright © 2022 UrbanAccess .NET, Support by{" "}
              <a target="_blank" href="http://www.dipointer.com">
                dipointer
              </a>
            </p>
          </div>
        </footer>
        <a id="back-to-top" href="#" class="show">
          <i class="flaticon-arrow-pointing-to-up"></i>
        </a>
      </div>
    </>
  );
}

export default Home;
