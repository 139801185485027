import React from "react";
import logo from "../logo.png";
import logosolid from "../logo-solid.png";
import imgbanner from "../image-banner-home6.png";
import imgbannerperson from "../image1-home6.png";
import imgpersonal from "../pricing1-home4-1.png";
import imgsoho from "../pricing2-home4-1.png";
import imgdedicated from "../pricing3-home4-1.png";
import { Link } from "react-router-dom";

function Home() {
  return (
    <>
      <div id="page" class="site">
        <header
          id="site-header"
          class="site-header header-style-1 home-6 header-overlay header-fullwidth sticky-header mobile-header-sticky"
        >
          <div class="octf-main-header">
            <div class="octf-area-wrap">
              <div class="container-fluid octf-mainbar-container">
                <div class="octf-mainbar">
                  <div class="octf-mainbar-row octf-row">
                    <div class="octf-col">
                      <div id="site-logo" class="site-logo">
                        <a href="/">
                          <img class="logo-static" src={logo} alt="Onum" />
                          <img class="logo-scroll" src={logo} alt="Onum" />
                        </a>
                      </div>
                    </div>

                    <div class="octf-col">
                      <nav id="site-navigation" class="main-navigation">
                        <ul id="primary-menu" class="menu">
                          <li
                            data-spy="scroll"
                            class="current-menu-item current-menu-ancestor"
                          >
                            <a href="#page">Home</a>
                          </li>
                          <li class="">
                            <a href="#product">Products</a>
                          </li>
                          <li class="">
                            <a href="#about">About</a>
                          </li>
                          <li class="">
                            <a href="#contact">Contact</a>
                          </li>
                          <li class="">
                            <Link to="minat">Registrasi Online</Link>
                          </li>
                          <li class="">
                            <Link to="/carabayar">Cara Bayar</Link>
                          </li>
                          <li class="">
                            <Link to="/career">Karir</Link>
                          </li>
                        </ul>
                      </nav>
                    </div>

                    <div class="octf-col text-right">
                      <div class="octf-btn-cta">
                        <div class="octf-header-module">
                          <div class="btn-cta-group">
                            <a
                              class="octf-btn octf-btn-primary"
                              href="tel:+622230501500"
                            >
                              <i class="fa fa-phone"></i>&nbsp; +62 22-3050 1500
                            </a>
                          </div>
                        </div>
                        <div class="octf-header-module">
                          <div class="btn-cta-group">
                            <a
                              class="octf-btn octf-btn-primary"
                              target="_blank"
                              href="mailto:sapa@urbanaccess.net"
                            >
                              <i class="fa fa-envelope"></i>&nbsp;
                              sapa@urbanaccess.net
                            </a>
                          </div>
                        </div>
                        <div class="octf-header-module">
                          <div class="toggle_search octf-cta-icons">
                            <i class="flaticon-search"></i>
                          </div>

                          <div class="h-search-form-field">
                            <div class="h-search-form-inner">
                              <form
                                target="_blank"
                                role="search"
                                method="get"
                                id="search-form"
                                class="search-form"
                                action="https://www.google.com/search?q=urbanaccess.net&oq=urbanaccess.net"
                              >
                                <input
                                  type="search"
                                  class="search-field"
                                  placeholder="Search…"
                                  value=""
                                  name="s"
                                />
                                <button type="submit" class="search-submit">
                                  <i class="flaticon-search"></i>
                                </button>
                              </form>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="header_mobile">
            <div class="container">
              <div class="mlogo_wrapper clearfix">
                <div class="mobile_logo">
                  <a href="/">
                    <img src={logosolid} alt="Onum" />
                  </a>
                </div>

                <div id="mmenu_toggle">
                  <button data-bs-target="#mobile_nav" aria-controls="mobile_nav"></button>
                </div>
              </div>

              <div class="mmenu_wrapper" id="mobile_nav">
                <div class="mobile_nav">
                  <ul id="menu-main-menu" class="mobile_mainmenu">
                    <li class="current-menu-item current-menu-ancestor">
                      <a href="/">Home</a>
                    </li>
                    <li class="">
                      <a href="#product">Products</a>
                    </li>
                    <li class="">
                      <a href="#about">About</a>
                    </li>
                    <li class="">
                      <a href="#contact">Contact</a>
                    </li>
                    <li class="">
                      <Link to="/carabayar">Cara Bayar</Link>
                    </li>
                    <li class="">
                      <Link to="/carabayar">Career</Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </header>

        <div id="content" class="site-content">
          <section class="home6-top">
            <div class="container">
              <div class="flex-row">
                <div class="col-md-6 col-sm-12 col-xs-12">
                  <div class="home6-top-img">
                    <img src={imgbanner} alt="" />
                  </div>
                </div>
                <div class="col-md-6 col-sm-12 col-xs-12">
                  <div class="home6-top-block">
                    <h6>U R B A N &nbsp; A C C E S S &nbsp; .net</h6>
                    <h2>The internet of your choice.</h2>
                    <p>
                      Pilihan Provider Internet <strong>TERBAIK</strong> untuk{" "}
                      <i>rumah</i> & <i>bisnis</i> anda, tanpa batas dan
                      batasan.
                    </p>
                    <div class="d-flex sm-justify-content-center">
                      <div class="video-popup">
                        <div class="btn-inner">
                          <a class="btn-play" href="#">
                            <i class="fas fa-play"></i>
                          </a>
                        </div>
                        <span>Apa itu URBANACCESS</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section class="home6-partners" id="about">
            <div class="container">
              <div class="flex-row">
                <div class="col-md-6 col-sm-12 col-xs-12 align-self-center">
                  <div class="home6-partners-block">
                    <div class="ot-heading left-align">
                      <h6 class="text-light">
                        <span>about us</span>
                      </h6>
                      <h2 class="text-light main-heading">
                        Nikmati layanan internet tanpa batas.
                      </h2>
                    </div>
                    <p class="font22">
                      Urban Access merupakan produk utama yang dimiliki oleh PT.
                      Media Distribusi Prima yang berlokasi di kota bandung.
                    </p>
                    <p>
                      Kami memiliki layanan internet super cepat dan tanpa batas
                      untuk rumah, kantoran dan perusahaan guna membantu akan
                      kebutuhan koneksi internet rumahan serta membantu
                      mengembangkan bisnis anda.
                    </p>
                    <div class="home6-partners-btn">
                      <a
                        href="#"
                        class="octf-btn octf-btn-primary octf-btn-icon"
                      >
                        <span>
                          More <i class="flaticon-right-arrow-1"></i>
                        </span>
                      </a>
                    </div>
                  </div>
                </div>
                <div class="col-md-6 col-sm-12 col-xs-12 align-self-center">
                  <div class="home6-partners-img">
                    <img src={imgbannerperson} alt="" />
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section class="home6-pricing" id="product">
            <div class="bg-overlay"></div>
            <div class="container">
              <div class="row">
                <div class="col-md-12 col-xs-12 text-center m-b40">
                  <div class="ot-heading">
                    <h6>
                      <span>tentukan pilihan paketmu</span>
                    </h6>
                    <h2 class="main-heading">Flexible Pricing Plans</h2>
                  </div>
                  <p class="text-light">
                    Kami memiliki pengalaman bekerja dengan bisnis kecil maupun
                    besar <br />
                    dan siap membantu mengembangkan strategi &amp; rencana yang
                    tapat untuk anda.
                  </p>
                </div>
              </div>
              <div class="row">
                <div class="col-md-4 sm-p-b60">
                  <div class="ot-pricing-table-h6 ot-pricing-table bg-shape s1">
                    <span class="title-table">PERSONAL</span>
                    <div class="inner-table">
                      <img src={imgpersonal} alt="Standard" />
                      <h2>URBANLITE</h2>
                      <p>Monthly Package</p>
                      <div class="details">
                        <ul>
                          <li class="text-left">
                            10 Mbps<span class="pull-right">Rp. 222.000</span>
                          </li>
                          <li class="text-left">
                            20 Mbps<span class="pull-right">Rp. 283.000</span>
                          </li>
                          <li class="text-left">
                            30 Mbps<span class="pull-right">Rp. 383.000</span>
                          </li>
                          <li class="text-left">
                            50 Mbps<span class="pull-right">Rp. 606.000</span>
                          </li>
                          <li class="text-left">
                            100 Mbps
                            <span class="pull-right">Rp. 1.009.000</span>
                          </li>
                          <li>Unlimited Quota</li>
                          <li>Wi-Fi, Fiber, Modem</li>
                          <li>IP Dynamic Private</li>
                          <li>24 Hours Support</li>
                          <li>3 Package Releases</li>
                        </ul>
                      </div>
                    </div>
                    <a
                      href="tel:+622230501500"
                      class="octf-btn octf-btn-icon octf-btn-secondary"
                    >
                      Call Us<i class="flaticon-viber"></i>
                    </a>
                  </div>
                </div>
                <div class="col-md-4 sm-p-b60">
                  <div class="ot-pricing-table-h6 ot-pricing-table bg-shape s2 feature">
                    <span class="title-table">SMALL OFFICE</span>
                    <div class="inner-table">
                      <img src={imgsoho} alt="Economy" />
                      <h2>URBANMAX</h2>
                      <p>Monthly Package</p>
                      <div class="details">
                        <ul>
                          <li class="text-left">
                            10 Mbps<span class="pull-right">Contact us</span>
                          </li>
                          <li class="text-left">
                            20 Mbps<span class="pull-right">Contact us</span>
                          </li>
                          <li class="text-left">
                            30 Mbps<span class="pull-right">Contact us</span>
                          </li>
                          <li class="text-left">
                            50 Mbps<span class="pull-right">Contact us</span>
                          </li>
                          <li class="text-left">
                            100 Mbps<span class="pull-right">Contact us</span>
                          </li>
                          <li class="text-left">
                            200 Mbps<span class="pull-right">Contact us</span>
                          </li>
                          <li>Unlimited Quota</li>
                          <li>Wi-Fi, Fiber, Modem</li>
                          <li>IP Dynamic Private / Public</li>
                          <li>24 Hours Support</li>
                          <li>3 Package Releases</li>
                        </ul>
                      </div>
                    </div>
                    <a
                      href="tel:+622230501500"
                      class="octf-btn octf-btn-icon octf-btn-third"
                    >
                      Call Us<i class="flaticon-viber"></i>
                    </a>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="ot-pricing-table-h6 ot-pricing-table bg-shape s2">
                    <span class="title-table">CORPORATE</span>
                    <div class="inner-table">
                      <img src={imgdedicated} alt="Executive" />
                      <h2>URBANULTIMATE</h2>
                      <p>Monthly Package</p>
                      <div class="details">
                        <ul>
                          <li class="text-left">
                            10 Mbps<span class="pull-right">Contact us</span>
                          </li>
                          <li class="text-left">
                            50 Mbps<span class="pull-right">Contact us</span>
                          </li>
                          <li class="text-left">
                            100 Mbps<span class="pull-right">Contact us</span>
                          </li>
                          <li class="text-left">
                            200 Mbps<span class="pull-right">Contact us</span>
                          </li>
                          <li class="text-left">
                            200 Mbps<span class="pull-right">Contact us</span>
                          </li>
                          <li>Unlimited Quota</li>
                          <li>Wi-Fi, Fiber, Modem</li>
                          <li>IP Public</li>
                          <li>24 Hours Support</li>
                          <li>3 Package Releases</li>
                        </ul>
                      </div>
                    </div>
                    <a
                      href="tel:+622230501500"
                      class="octf-btn octf-btn-icon octf-btn-primary"
                    >
                      Call Us<i class="flaticon-viber"></i>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <br />
            <br />
            <p class="text-light text-center">
              Mohon maaf atas kesalahan informasi harga URBANMAX, terkait sedang
              dilakukan pengembangan website kami.
            </p>
          </section>
          <section class="home6-pricing" style={{ margin: 0 }} id="contact">
            <div class="container">
              <div class="row flex-row">
                <div class="col-md-6 col-xs-12 sm-m-b60">
                  <div class="our-contact text-light">
                    <div class="ot-heading text-light">
                      <h6>
                        <span>contact details</span>
                      </h6>
                      <h2 class="main-heading">Our Contacts </h2>
                    </div>
                    <div class="contact-info text-light m-b30">
                      <i class="flaticon-world"></i>
                      <div class="info-text">
                        <h6>Alamat:</h6>
                        {/* <p style={{ fontSize: "17px" }}>
                          Jl. Cetarip kulon I No.158, Kopo Bandung.
                        </p>
                        --> */}
                        <p style={{ fontSize: "17px" }}>
                          Jl. Situsari VIII No.12, Cijagra, Lengkong Bandung 40265
                        </p>
                      </div>
                    </div>
                    <div class="contact-info text-light m-b30">
                      <i class="flaticon-note"></i>
                      <div class="info-text">
                        <h6>Email:</h6>
                        <p>
                          <a href="mailto:sapa@urbanacccess.net">
                            sapa@urbanacccess.net
                          </a>
                        </p>
                      </div>
                    </div>
                    <div class="contact-info text-light">
                      <i class="flaticon-viber"></i>
                      <div class="info-text">
                        <h6>Telephone:</h6>
                        <p>
                          <a href="+62 22-3050-1500">+62 22-3050-1500</a>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-6 col-xs-12 align-self-center">
                  <div class="form-contact">
                    <div class="ot-heading">
                      <h6>
                        <span>GET IN TOUCH</span>
                      </h6>
                      <h2 class="main-heading">Ready to Get Started?</h2>
                      <p class="text-light">
                        Hubungi kami kapan saja, kami berusaha menjawab semua
                        pertanyaan dalam waktu 24 jam pada setiap hari. Kami
                        akan dengan senang hati menjawab pertanyaan Anda.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>

        <footer
          id="site-footer"
          class="site-footer site-footer-2 bg-gradient-2"
        >
          <div class="footer-bottom"></div>
          <div class="container">
            <p class="copyright-2 text-center">
              {" "}
              Disclaimer : <br /> Harga yang tercantum dalam website dapat
              berubah sewaktu-waktu sesuai dengan syarat dan ketentuan yang
              berlaku.
              <br />
              Untuk informasi lebih lanjut, silakan hubungi Customer Care kami
              di +62 22 30501500 atau melalui email ke sapa@urbanaccess.net.{" "}
            </p>
            <p class="copyright-2 text-center">
              Copyright © 2022 UrbanAccess .NET, Support by{" "}
              <a target="_blank" href="http://www.dipointer.com">
                dipointer
              </a>
            </p>
          </div>
        </footer>
        <a id="back-to-top" href="#" class="show">
          <i class="flaticon-arrow-pointing-to-up"></i>
        </a>
      </div>
    </>
  );
}

export default Home;
