import Joi from "joi";
const customTldRegex = new RegExp(
  /^(?:[a-zA-Z0-9](?:(?:[a-zA-Z0-9-]){0,61}[a-zA-Z0-9])?\.)+(?:[a-zA-Z]{2,})$|(?:[a-zA-Z0-9](?:(?:[a-zA-Z0-9-]){0,61}[a-zA-Z0-9])?\.)+(?:com|org|net|edu|gov|mil|biz|info|mobi|name|aero|asia|jobs|museum|coop|travel|[a-zA-Z]{2})$/i
);
export const schemaminat = Joi.object({
  nama_peminat: Joi.string().required(),
  id_provinsi: Joi.string().required(),
  id_kabupaten: Joi.string().required(),
  id_kecamatan: Joi.string().required(),
  id_kelurahan: Joi.string().required(),
  kode_pos: Joi.string().required(),
  rt: Joi.string().max(2).required(),
  rw: Joi.string().max(2).required(),
  koordinat: Joi.string(),
  alamat: Joi.string().required(),
  telp_kontak: Joi.string().required(),
  whatsapp_kontak: Joi.string().required(),
  email_kontak: Joi.string()
    .email({
      minDomainSegments: 2,
      tlds: { allow: false },
    })
    .regex(customTldRegex)
    .required(),
  id_layanan_internet: Joi.string().required(),
  id_layanan_internet_detail: Joi.string().required(),
});
