import React, { useEffect, useState, useRef } from "react";
import {
  fetchLayanan,
  fetchKecepatan,
  fetchProvinsi,
  fetchKabupaten,
  fetchKecamatan,
  fetchKelurahan,
} from "../utils/data";
import { postMinat } from "../utils/submission";
import { schemaminat } from "../utils/schema";
import { joiResolver } from "@hookform/resolvers/joi";
import { useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import { generateKodeMinat } from "../utils/generator";
import { showAlert } from "../component/alert";

function Minat() {
  const year = new Date();
  const [layanan, setLayanan] = useState([]);
  const [kecepatan, setKecepatan] = useState([]);
  const [idlayanan, setIdlayanan] = useState(null);
  const [idprovinsi, setIdprovinsi] = useState(null);
  const [idkabupaten, setIdkabupaten] = useState(null);
  const [idkecamatan, setIdkecamatan] = useState(null);
  const [provinsi, setProvinsi] = useState([]);
  const [kabupaten, setKabupaten] = useState([]);
  const [kecamatan, setKecamatan] = useState([]);
  const [kelurahan, setKelurahan] = useState([]);
  const [payload, setPayload] = useState(false);
  // validation joi

  const {
    register,
    handleSubmit,
    formState,
    formState: { errors, isSubmitSuccessful },
    reset,
  } = useForm({
    resolver: joiResolver(schemaminat),
  });
  const handleChange = (e) => {
    setIdlayanan(e.target.value);
  };

  useEffect(() => {
    if (idlayanan === null) {
      fetchLayanan()
        .then((data) => setLayanan(data.items))
        .catch((err) => console.error(err));
    } else if (idlayanan !== null) {
      fetchKecepatan(idlayanan)
        .then((data) => setKecepatan(data.items))
        .catch((err) => console.error(err));
    }

    if (idprovinsi === null) {
      fetchProvinsi()
        .then((res) => setProvinsi(res.items))
        .catch((err) => console.error(err));
    } else if (idprovinsi !== null) {
      fetchKabupaten(idprovinsi)
        .then((res) => setKabupaten(res))
        .catch((err) => console.error(err));
    }

    if (idkabupaten !== null) {
      fetchKecamatan(idkabupaten)
        .then((res) => setKecamatan(res))
        .catch((err) => console.error(err));
    }

    if (idkecamatan !== null) {
      fetchKelurahan(idkecamatan)
        .then((res) => setKelurahan(res))
        .catch((err) => console.error(err));
    }
    if (formState.isSubmitSuccessful) {
      reset({
        nama_peminat: "",
        id_provinsi: "",
        id_kabupaten: "",
        id_kecamatan: "",
        id_kelurahan: "",
        kode_pos: "",
        rt: "",
        rw: "",
        koordinat: "",
        alamat: "",
        telp_kontak: "",
        whatsapp_kontak: "",
        email_kontak: "",
        id_layanan_internet: "",
        id_layanan_internet_detail: "",
      });
    }
  }, [idlayanan, idprovinsi, idkabupaten, idkecamatan, payload]);

  return (
    <div class="container">
      <main>
        <div class="py-5 text-center">
          <img
            class="d-block mx-auto mb-4 w-25"
            src="https://internal.urbanaccess.net/assets/images/logo/logogram-urban-acess-blue.png"
            alt=""
          />
          <h2>Form Minat Berlangganan</h2>
          {payload && (
            <>
              <div
                className="position-absolute top-50 start-50 translate-middle"
                style={{ zIndex: "999999999" }}
              >
                <div class="spinner-border text-primary " role="status">
                  <span class="visually-hidden">Loading...</span>
                </div>
                <span className="text-white"> Mengirimkan Data...</span>
              </div>
              <div class="modal-backdrop fade show"></div>
            </>
          )}

          <p class="lead">
            Silakan isi data diri, team kami akan segera menghubungi anda untuk
            proses selanjutnya.
          </p>
        </div>
        <div class="">
          <form
            class="row g-5 justify-content-center"
            onSubmit={handleSubmit((d) => {
              setPayload(true);
              postMinat({
                kode_minat: generateKodeMinat(8),
                id_sales: "idSales618bed479c484",
                source: "1",
                ...d,
              })
                .then((res) => {
                  showAlert(true, res.message);
                  setPayload(false);
                })
                .catch((err) => {
                  showAlert(false, err.message);
                  setPayload(false);
                });
            })}
          >
            <div class="col-md-5 col-lg-4 order-md-last">
              <h4 class="d-flex justify-content-between align-items-center mb-3">
                <span class="text-primary">Pilih Layanan</span>
              </h4>
              <div class="row g-3">
                <div class="col-sm-12">
                  <label for="whatsapp" class="form-label">
                    Layanan
                  </label>
                  <div class="input-group has-validation">
                    <select
                      class="form-select"
                      aria-label="Default select example"
                      {...register("id_layanan_internet", {
                        required: "Pilih layanan internet",
                        onChange: handleChange,
                      })}
                    >
                      <option selected hidden>
                        Pilih Layanan
                      </option>
                      {layanan.length !== 0 &&
                        layanan.map((item) => (
                          <option value={item.id_layanan_internet}>
                            {item.nama_layanan}
                          </option>
                        ))}
                    </select>
                    <ErrorMessage
                      errors={errors}
                      name="id_layanan_internet"
                      render={({ message }) => (
                        <p className="text-danger">{message}</p>
                      )}
                    />
                  </div>
                </div>
                <div class="col-sm-12">
                  <label for="whatsapp" class="form-label">
                    Kecepatan Layanan
                  </label>
                  <div class="input-group has-validation">
                    <select
                      class="form-select"
                      aria-label="Default select example"
                      disabled={idlayanan !== null ? false : true}
                      {...register("id_layanan_internet_detail", {
                        required: "Pilih kecepatan internet",
                      })}
                    >
                      <option selected hidden>
                        Pilih Kecepatan
                      </option>
                      {kecepatan.length !== 0 &&
                        kecepatan.map((item) => (
                          <option value={item.id_layanan_internet_detail}>
                            {item.nama_layanan} - {item.kecepatan} mbps
                          </option>
                        ))}
                    </select>
                  </div>
                  <ErrorMessage
                    errors={errors}
                    name="id_layanan_internet_detail"
                    render={({ message }) => (
                      <p className="text-danger">{message}</p>
                    )}
                  />
                </div>
              </div>
            </div>
            <div class="col-md-7 col-lg-8">
              <h4 class="mb-3">Data Peminat</h4>

              <div class="row g-3">
                <div class="col-sm-6">
                  <label for="firstName" class="form-label">
                    Nama Pemohon
                  </label>
                  <input
                    type="text"
                    class="form-control"
                    id="firstName"
                    placeholder="Nama Lengkap"
                    required
                    {...register("nama_peminat", {
                      required: "Nama pemohon diperlukan",
                    })}
                  />
                  <ErrorMessage
                    errors={errors}
                    name="nama_peminat"
                    render={({ message }) => (
                      <p className="text-danger">{message}</p>
                    )}
                  />
                </div>
                <div class="col-sm-6">
                  <label for="lastName" class="form-label">
                    Email
                  </label>
                  <input
                    type="text"
                    class="form-control"
                    id="lastName"
                    placeholder="Email"
                    required
                    {...register("email_kontak", {
                      required: "Email diperlukan",
                    })}
                  />
                  <ErrorMessage
                    errors={errors}
                    name="email_kontak"
                    render={({ message }) => (
                      <p className="text-danger">{message}</p>
                    )}
                  />
                </div>
                <div class="col-sm-12 col-md-6">
                  <label for="whatsapp" class="form-label">
                    WhatsApp
                  </label>
                  <div class="input-group has-validation">
                    <span class="input-group-text">
                      <i class="bi bi-whatsapp"></i>
                    </span>
                    <input
                      type="text"
                      class="form-control"
                      id="whatsapp"
                      placeholder="Nomor Aktif"
                      required
                      {...register("whatsapp_kontak", {
                        required: "Whatsapp diperlukan",
                      })}
                    />
                    <ErrorMessage
                      errors={errors}
                      name="whatsapp_kontak"
                      render={({ message }) => (
                        <p className="text-danger">{message}</p>
                      )}
                    />
                  </div>
                </div>
                <div class="col-sm-12 col-md-6">
                  <label for="whatsapp" class="form-label">
                    Telpon
                  </label>
                  <div class="input-group has-validation">
                    <span class="input-group-text">
                      <i class="bi bi-telephone-fill"></i>
                    </span>
                    <input
                      type="text"
                      class="form-control"
                      id="whatsapp"
                      placeholder="Nomor Aktif"
                      required
                      {...register("telp_kontak", {
                        required: "No. Telepon diperlukan",
                      })}
                    />
                    <ErrorMessage
                      errors={errors}
                      name="telp_kontak"
                      render={({ message }) => (
                        <p className="text-danger">{message}</p>
                      )}
                    />
                  </div>
                </div>
                <div class="col-12">
                  <label for="email" class="form-label">
                    Alamat Lengkap{" "}
                  </label>
                  <textarea
                    class="form-control"
                    id="email"
                    placeholder="Contoh : Jl. Kangkung Kidul no. 18"
                    {...register("alamat", { required: "Alamat Diperlukan" })}
                  ></textarea>
                  <ErrorMessage
                    errors={errors}
                    name="alamat"
                    render={({ message }) => (
                      <p className="text-danger">{message}</p>
                    )}
                  />
                </div>
                <div class="col-sm-12 col-md-4">
                  <label for="address" class="form-label">
                    RT
                  </label>
                  <input
                    type="text"
                    class="form-control"
                    id="address"
                    placeholder="1234 Main St"
                    required
                    {...register("rt", { required: "RT Diperlukan" })}
                  />
                  <ErrorMessage
                    errors={errors}
                    name="rt"
                    render={({ message }) => (
                      <p className="text-danger">{message}</p>
                    )}
                  />
                </div>
                <div class="col-sm-12 col-md-4">
                  <label for="address" class="form-label">
                    RW
                  </label>
                  <input
                    type="text"
                    class="form-control"
                    id="address"
                    placeholder="1234 Main St"
                    required
                    {...register("rw", { required: "RW Diperlukan" })}
                  />
                  <ErrorMessage
                    errors={errors}
                    name="rw"
                    render={({ message }) => (
                      <p className="text-danger">{message}</p>
                    )}
                  />
                </div>
                <div class="col-sm-12 col-md-4">
                  <label for="address" class="form-label">
                    Kode Pos
                  </label>
                  <input
                    type="text"
                    class="form-control"
                    id="address"
                    placeholder="1234 Main St"
                    required
                    {...register("kode_pos", {
                      required: "Kode Pos Diperlukan",
                    })}
                  />
                  <ErrorMessage
                    errors={errors}
                    name="kode_pos"
                    render={({ message }) => (
                      <p className="text-danger">{message}</p>
                    )}
                  />
                </div>
                <div class="col-sm-12 col-md-3">
                  <label for="whatsapp" class="form-label">
                    Provinsi
                  </label>
                  <div class="input-group has-validation">
                    <select
                      class="form-select"
                      aria-label="Default select example"
                      {...register("id_provinsi", {
                        onChange: (e) => setIdprovinsi(e.target.value),
                        required: "Provinsi harus diisi",
                      })}
                    >
                      <option selected hidden>
                        Pilih Provinsi
                      </option>
                      {provinsi.map((item) => (
                        <option value={item.id_provinsi}>
                          {item.nama_provinsi}
                        </option>
                      ))}
                    </select>
                    <ErrorMessage
                      errors={errors}
                      name="id_provinsi"
                      render={({ message }) => (
                        <p className="text-danger">{message}</p>
                      )}
                    />
                  </div>
                </div>
                <div class="col-sm-12 col-md-3">
                  <label for="whatsapp" class="form-label">
                    Kabupaten
                  </label>
                  <div class="input-group has-validation">
                    <select
                      class="form-select"
                      aria-label="Default select example"
                      disabled={idprovinsi !== null ? false : true}
                      {...register("id_kabupaten", {
                        onChange: (e) => setIdkabupaten(e.target.value),
                        required: "Kabupaten harus diisi",
                      })}
                    >
                      <option selected hidden>
                        Pilih Kabupaten
                      </option>
                      {kabupaten.map((item) => (
                        <option value={item.id_kabupaten}>
                          {item.nama_kabupaten}
                        </option>
                      ))}
                    </select>
                    <ErrorMessage
                      errors={errors}
                      name="id_kabupaten"
                      render={({ message }) => (
                        <p className="text-danger">{message}</p>
                      )}
                    />
                  </div>
                </div>
                <div class="col-sm-12 col-md-3">
                  <label for="whatsapp" class="form-label">
                    Kecamatan
                  </label>
                  <div class="input-group has-validation">
                    <select
                      class="form-select"
                      aria-label="Default select example"
                      disabled={idkabupaten !== null ? false : true}
                      {...register("id_kecamatan", {
                        onChange: (e) => setIdkecamatan(e.target.value),
                        required: "Kecamatan harus diisi",
                      })}
                    >
                      <option selected hidden>
                        Pilih Kecamatan
                      </option>
                      {kecamatan.map((item) => (
                        <option value={item.id_kecamatan}>
                          {item.nama_kecamatan}
                        </option>
                      ))}
                    </select>
                    <ErrorMessage
                      errors={errors}
                      name="id_kecamatan"
                      render={({ message }) => (
                        <p className="text-danger">{message}</p>
                      )}
                    />
                  </div>
                </div>
                <div class="col-sm-12 col-md-3">
                  <label for="whatsapp" class="form-label">
                    Kelurahan
                  </label>
                  <div class="input-group has-validation">
                    <select
                      class="form-select"
                      aria-label="Default select example"
                      disabled={idkecamatan !== null ? false : true}
                      {...register("id_kelurahan", {
                        required: "Kelurahan harus diisi",
                      })}
                    >
                      <option selected hidden>
                        Pilih Kelurahan
                      </option>
                      {kelurahan.map((item) => (
                        <option value={item.id_kelurahan}>
                          {item.nama_kelurahan}
                        </option>
                      ))}
                    </select>
                    <ErrorMessage
                      errors={errors}
                      name="id_kelurahan"
                      render={({ message }) => (
                        <p className="text-danger">{message}</p>
                      )}
                    />
                  </div>
                </div>
                <div class="col-12">
                  <label for="address" class="form-label">
                    Koordinat
                  </label>
                  <input
                    type="text"
                    class="form-control"
                    id="address"
                    placeholder="1234 Main St"
                    required
                    {...register("koordinat", {
                      required: "Koordinat harus diisi",
                    })}
                  />
                  <ErrorMessage
                    errors={errors}
                    name="koordinat"
                    render={({ message }) => (
                      <p className="text-danger">{message}</p>
                    )}
                  />
                </div>
                {/* 
                <hr class="my-4" />

                <div class="form-check">
                  <input
                    type="checkbox"
                    class="form-check-input"
                    id="same-address"
                  />
                  <label class="form-check-label" for="same-address">
                    Shipping address is the same as my billing address
                  </label>
                </div>

                <div class="form-check">
                  <input
                    type="checkbox"
                    class="form-check-input"
                    id="save-info"
                  />
                  <label class="form-check-label" for="save-info">
                    Save this information for next time
                  </label>
                </div>

                <hr class="my-4" />

                <h4 class="mb-3">Payment</h4>

                <div class="my-3">
                  <div class="form-check">
                    <input
                      id="credit"
                      name="paymentMethod"
                      type="radio"
                      class="form-check-input"
                      checked
                      required
                    />
                    <label class="form-check-label" for="credit">
                      Credit card
                    </label>
                  </div>
                  <div class="form-check">
                    <input
                      id="debit"
                      name="paymentMethod"
                      type="radio"
                      class="form-check-input"
                      required
                    />
                    <label class="form-check-label" for="debit">
                      Debit card
                    </label>
                  </div>
                  <div class="form-check">
                    <input
                      id="paypal"
                      name="paymentMethod"
                      type="radio"
                      class="form-check-input"
                      required
                    />
                    <label class="form-check-label" for="paypal">
                      PayPal
                    </label>
                  </div>
                </div>

                <div class="row gy-3">
                  <div class="col-md-6">
                    <label for="cc-name" class="form-label">
                      Name on card
                    </label>
                    <input
                      type="text"
                      class="form-control"
                      id="cc-name"
                      placeholder=""
                      required
                    />
                    <small class="text-body-secondary">
                      Full name as displayed on card
                    </small>
                    <div class="invalid-feedback">Name on card is required</div>
                  </div>

                  <div class="col-md-6">
                    <label for="cc-number" class="form-label">
                      Credit card number
                    </label>
                    <input
                      type="text"
                      class="form-control"
                      id="cc-number"
                      placeholder=""
                      required
                    />
                    <div class="invalid-feedback">
                      Credit card number is required
                    </div>
                  </div>

                  <div class="col-md-3">
                    <label for="cc-expiration" class="form-label">
                      Expiration
                    </label>
                    <input
                      type="text"
                      class="form-control"
                      id="cc-expiration"
                      placeholder=""
                      required
                    />
                    <div class="invalid-feedback">Expiration date required</div>
                  </div>

                  <div class="col-md-3">
                    <label for="cc-cvv" class="form-label">
                      CVV
                    </label>
                    <input
                      type="text"
                      class="form-control"
                      id="cc-cvv"
                      placeholder=""
                      required
                    />
                    <div class="invalid-feedback">Security code required</div>
                  </div>
                </div> */}
                <hr class="my-4" />
                <input class="w-100 btn btn-primary btn-lg" type="submit" />
              </div>
            </div>
          </form>
        </div>
      </main>

      <footer class="my-5 pt-5 text-body-secondary text-center text-small">
        <p class="mb-1">&copy; {year.getFullYear()} Urban Access</p>
        {/* <ul class="list-inline">
          <li class="list-inline-item">
            <a href="#">Privacy</a>
          </li>
          <li class="list-inline-item">
            <a href="#">Terms</a>
          </li>
          <li class="list-inline-item">
            <a href="#">Support</a>
          </li>
        </ul> */}
      </footer>
    </div>
  );
}

export default Minat;
