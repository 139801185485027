import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Home from "./pages/Home";
import Notfound from "./pages/Notfound";
import Minat from "./pages/Minat";
import Carabayar from "./pages/Carabayar";
import Career from "./pages/Career";

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    errorElement: <Notfound />,
    children: [
      {
        path: "",
        element: <Home />,
      },
      {
        path: "minat",
        element: <Minat />,
      },
      {
        path: "carabayar",
        element: <Carabayar />,
      },
      {
        path: "Career",
        element: <Career />,
      },
    ],
  },
]);
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
