import axios from "axios";

export const fetchLayanan = async () => {
  const response = await axios.get(
    `${process.env.REACT_APP_HOST}/get-minat-layanan`,
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
  return response.data;
};

export const fetchKecepatan = async (id) => {
  const response = await axios.get(
    `${process.env.REACT_APP_HOST}/kecepatan-internet/get/${id}`,
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
  return response.data;
};

export const fetchProvinsi = async () => {
  const response = await axios.get(
    `${process.env.REACT_APP_HOST}/get-minat-provinsi`,
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
  return response.data;
};

export const fetchKabupaten = async (id) => {
  const response = await axios.get(
    `${process.env.REACT_APP_HOST}/kabupaten/get/${id}`,
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
  return response.data;
};

export const fetchKecamatan = async (id) => {
  const response = await axios.get(
    `${process.env.REACT_APP_HOST}/kecamatan/get/${id}`,
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
  return response.data;
};

export const fetchKelurahan = async (id) => {
  const response = await axios.get(
    `${process.env.REACT_APP_HOST}/kelurahan/get/${id}`,
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
  return response.data;
};
