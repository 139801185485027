import React from "react";
import "./Notfound.css";
import { Link } from "react-router-dom";

function Notfound() {
  return (
    <div className="notfound">
      <h1>404</h1>
      <div class="cloak__wrapper">
        <div class="cloak__container">
          <div class="cloak"></div>
        </div>
      </div>
      <div class="info">
        <h2>Maaf, halaman yang anda cari tidak ada</h2>
        <p>Silakan kembali ke halaman utama untuk Urban Access</p>
        <Link to="/">Kembali</Link>
      </div>
    </div>
  );
}

export default Notfound;
